
import { defineComponent, onMounted } from "vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import WeeklyStats from "@/components/widgets/WeeklySales.vue";
import ApiService from "@/core/services/ApiService";

export default defineComponent({
  name: "dashboard",
  components: {
    WeeklyStats,
  },
  data() {
    return {
      statsData: {}
    };
  },
  mounted() {
    ApiService.get('stats').then((result)=>{
      this.statsData = result.data;
      console.log(this.statsData);
    })
  },
  setup() {
    onMounted(() => {
      setCurrentPageTitle("Dashboard");
    });
  },
});
