
import { defineComponent, ref, watch } from "vue";
import { getCSSVariableValue } from "@/assets/ts/_utils";
import { VueApexChartsComponent } from "vue3-apexcharts";

interface GraphSeries {
  name: string,
  data: number[],
}

export default defineComponent({
  name: "weekly-stats",
  props: {
    series: {
      type: Array,
      required: true,
    },
    categories: {
      type: Array,
      required: true,
    },
    totals: {
      type: Object,
      required: true,
    },
    subtotals: {
      type: Object,
      required: true,
    },
    widgetClasses: String,
    chartColor: String,
    chartHeight: String,
  },

  mounted() {
    watch(this.categories,  () => {
      alert(this.categories);
      this.chartOptions.xaxis.categories = this.categories;
    }, { deep: true });
  },

  setup(props) {
    const color = ref(props.chartColor);
    const labelColor = getCSSVariableValue("--bs-" + "gray-800");
    const strokeColor = getCSSVariableValue("--bs-" + "gray-300");
    const baseColor = getCSSVariableValue("--bs-" + color.value);
    const lightColor = getCSSVariableValue("--bs-light-" + color.value);

    let chartOptions = {
      chart: {

        fontFamily: "inherit",
        height: props.chartHeight,
        toolbar: {
          show: false,
        },
        zoom: {
          enabled: false,
        },
        sparkline: {
          enabled: true,
        },
      },
      plotOptions: {bar: {
          horizontal: true
        }},
      legend: {
        show: false,
      },
      dataLabels: {
        enabled: false,
      },
      fill: {
        type: "solid",
        color: lightColor,
        opacity: 0.2,
      },
      stroke: {
        curve: "smooth",
        show: true,
        width: 3,
      },
      xaxis: {
        categories: props.categories,
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        labels: {
          show: true,
          style: {
            colors: labelColor,
            fontSize: "12px",
          },
        },
        crosshairs: {
          show: false,
          position: "front",
          stroke: {
            color: strokeColor,
            width: 1,
            dashArray: 3,
          },
        },
        tooltip: {
          enabled: false,
        },
      },
      yaxis: {
        labels: {
          show: false,
          style: {
            colors: labelColor,
            fontSize: "12px",
          },
        },
      },
      states: {
        normal: {
          filter: {
            type: "none",
            value: 0,
          },
        },
        hover: {
          filter: {
            type: "none",
            value: 0,
          },
        },
        active: {
          allowMultipleDataPointsSelection: false,
          filter: {
            type: "none",
            value: 0,
          },
        },
      },
      tooltip: {
        style: {
          fontSize: "12px",
        },
        y: {
          formatter: function (val) {
            return val;
          },
        },
      },
      markers: {
        strokeColor: [baseColor],
        strokeWidth: 1,
      },
    };

    return {
      chartOptions,
    };
  },
});
